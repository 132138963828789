import React, { useState, useEffect } from 'react'
import { CircularProgress, Select, MenuItem } from '@material-ui/core'
import { LineChart, ReferenceLine, Line, XAxis, ResponsiveContainer, CartesianGrid } from 'recharts'
import { CollapsibleCard } from 'src/components'
import * as Types from 'src/common/types'
import { API } from 'src/api'
import styles from './DeviceActivity.module.scss'

interface Props {
  device: Types.Device
}

export const DeviceActivity: React.FC<Props> = ({ device }) => {
  const [activityGraph, setActivityGraph] = useState<Types.DeviceActivityGraphData | null>(null)
  const [graphLength, setGraphLength] = useState(30)
  const activityGraphDisplayHeight = 150

  useEffect(() => {
    async function updateDeviceActivityGraph() {
      const r = await API.Device.deviceActivityGraph(device.imei, graphLength)
      if (r.result === 'ok') {
        setActivityGraph(r.message)
      }
    }
    updateDeviceActivityGraph()
  }, [graphLength])

  function XAxisTickFormatter(tick: string) {
    const d = new Date().setHours(12, 0, 0, 0)
    const tickDate = new Date(d - 86400 * 1000 * (graphLength - parseInt(tick) - 1))
    return `${(tickDate.getMonth() + 1).toString().padStart(2, '0')}/${tickDate
      .getDate()
      .toString()
      .padStart(2, '0')}`
  }

  return (
    <CollapsibleCard
      title='Activity'
      subtitle={graphLength.toString() + ' day general activity chart'}
      openByDefault
      actionSet={
        <Select
          className={styles.select}
          variant='outlined'
          value={graphLength}
          onChange={(e) => setGraphLength(e.target.value as number)}
        >
          <MenuItem value={14}>14 days</MenuItem>
          <MenuItem value={30}>30 days</MenuItem>
          <MenuItem value={60}>60 days</MenuItem>
        </Select>
      }
    >
      <div className={styles.graphContainer}>
        {activityGraph ? (
          <div className={styles.activityChart}>
            <ResponsiveContainer width='98%' height={activityGraphDisplayHeight}>
              <LineChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} data={activityGraph}>
                <CartesianGrid stroke='#bbb' strokeDasharray='3 3' />
                <XAxis values='time' tickFormatter={XAxisTickFormatter} />
                <ReferenceLine
                  y={
                    activityGraph
                      .map((d) => d.value)
                      .reduce((a, b) => {
                        return a + b
                      }, 0) / activityGraph.length
                  }
                  stroke='#666'
                />
                <Line
                  dataKey='value'
                  type='monotone'
                  strokeWidth={2.5}
                  dot={false}
                  isAnimationActive={false}
                  stroke='#f4b519'
                  strokeLinecap='round'
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    </CollapsibleCard>
  )
}

import React, { useContext } from "react";
import { GlobalContext } from "src/App";
import { CircularProgress } from "@material-ui/core";
import { DeviceFilter } from "./DeviceFilter/DeviceFilter";
import { DeviceList } from "./DeviceList/DeviceList";
import DeviceMap from "./DeviceMap";
import DeviceMeasurements from "./DeviceMeasurements";

import styles from "./HomePage.module.scss";

export const HomePage: React.FC = () => {
  const { devices, lastMeasurements, userData, deviceFilter } =
    useContext(GlobalContext);

  if (!devices || !userData || !lastMeasurements) {
    return (
      <div className={styles.loadingIndicator}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DeviceFilter userData={userData} />
      <DeviceMap devices={devices} userData={userData} />
      <DeviceMeasurements />
      <DeviceList
        devices={devices}
        userData={userData}
        lastMeasurements={lastMeasurements}
        deviceFilter={deviceFilter}
      />
    </div>
  );
};

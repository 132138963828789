import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import {
  ArrowBackRounded,
  BatteryFullRounded as BatteryIcon,
  OfflineBoltRounded as SolarIcon,
  Schedule as ActivityIcon,
  Brightness7Rounded as TemperatureIcon,
  OpacityRounded as HumidityIcon,
  EmojiNatureRounded as DetectionCountDeltaIcon,
  Code as FirmwareIcon,
  SyncAlt as FirmwareUpdateIcon,
  SignalCellularAltRounded as RSSIIcon,
} from "@material-ui/icons";
import * as Types from "src/common/types";
import * as Utils from "src/common/utils";
import styles from "./DeviceOverview.module.scss";
import { useHistory } from "react-router";

interface DeviceParameterProps {
  label: string;
  value: string | number | null;
  fixedDisplay?: boolean;
  unit?: string;
  warningMax?: number;
  criticalMax?: number;
  Icon?: any;
}

const DeviceParameter: React.FC<DeviceParameterProps> = ({
  label,
  value,
  fixedDisplay,
  unit,
  warningMax,
  criticalMax,
  Icon,
}) => {
  const val =
    fixedDisplay && value !== null && typeof value === "number"
      ? (value as number).toFixed(2)
      : value;
  let displayValue = `${val}${unit ? " " + unit : ""}`;
  if (val === null) displayValue = "...";

  let crit = false;
  let warn = false;

  if (value !== null && criticalMax && warningMax) {
    warn = value < warningMax;
    crit = value < criticalMax;
  }

  return (
    <Tooltip
      placement="top"
      arrow
      title={<p className={styles.tooltip}>{label}</p>}
    >
      <div
        className={`${styles.parameter} ${warn ? styles.warning : null} ${
          crit ? styles.critical : null
        }`}
      >
        {Icon ? <Icon className={styles.icon} /> : null}
        {displayValue}
      </div>
    </Tooltip>
  );
};

interface SelectOptionProps {
  label: string;
  options: { id: number; name: string }[];
  value: number;
  onChange: (value: number) => void;
  small?: boolean;
  highlighted?: boolean;
}

const SelectOption: React.FC<SelectOptionProps> = ({
  label,
  options,
  value,
  onChange,
  small,
  highlighted,
}) => {
  function handleChange(e: React.ChangeEvent<{ value: unknown }>) {
    onChange(e.target.value as number);
  }

  return (
    <FormControl
      size="small"
      variant="outlined"
      color="secondary"
      className={`${styles.option} ${small ? styles.small : null}`}
    >
      <InputLabel id={"select-option-label-" + label}>{label}</InputLabel>
      <Select
        id={"select-option-" + label}
        labelId={"select-option-label-" + label}
        label={label}
        onChange={handleChange}
        value={value}
        className={`${styles.input} ${highlighted ? styles.highlighted : null}`}
      >
        {options.map((o, idx) => (
          <MenuItem key={idx} value={o.id}>
            {o.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface Props {
  userData: Types.UserData;
  device: Types.Device;
  lastMeasurement: Types.Measurement | null;
  updateDevice: (data: { [imei: string]: Types.DeviceUpdateData }) => void;
}

export const DeviceOverview: React.FC<Props> = ({
  userData,
  device,
  lastMeasurement,
  updateDevice,
}) => {
  const [location, setLocation] = useState(device.locationConfigID);
  const [crop, setCrop] = useState(device.cropID);
  const [pest, setPest] = useState(device.pestID);
  const [commissionStatus, setCommissionStatus] = useState(
    device.commissionStatusID
  );
  const [config, setConfig] = useState(device.operationConfigID);
  const [targetFirmware, setTargetFirmware] = useState(device.targetFirmwareID);
  const history = useHistory();

  useEffect(() => {
    if (targetFirmware === 0) setTargetFirmware(null);
  }, [targetFirmware]);

  function onDiscardChanges() {
    setLocation(device.locationConfigID);
    setCrop(device.cropID);
    setPest(device.pestID);
    setCommissionStatus(device.commissionStatusID);
    setConfig(device.operationConfigID);
    setTargetFirmware(device.targetFirmwareID);
  }

  async function onApplyChanges() {
    updateDevice({
      [device.imei]: {
        locationConfigID: location,
        cropID: crop,
        pestID: pest,
        commissionStatusID: commissionStatus,
        operationConfigID: config,
        targetFirmwareID: targetFirmware,
      },
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <ArrowBackRounded
          onClick={() => history.goBack()}
          className={styles.backButton}
        />

        <span>
          <h1>{device.uid}</h1>
          <h2>{device.imei}</h2>
        </span>
      </div>

      <h2>Current parameters</h2>
      <h3>Core device parameters and diagnostics</h3>

      <div className={styles.parameterContainer}>
        <DeviceParameter
          label="Last activity"
          value={Utils.getDateTimeString(device.lastActivity)}
          Icon={ActivityIcon}
        />
        <DeviceParameter
          label="Battery voltage"
          value={device.batteryVoltage}
          fixedDisplay
          unit="V"
          Icon={BatteryIcon}
          warningMax={3.9}
          criticalMax={3.6}
        />
        <DeviceParameter
          label="Solar cell voltage"
          value={device.solarCellVoltage}
          fixedDisplay
          unit="V"
          Icon={SolarIcon}
          warningMax={10}
          criticalMax={8}
        />
        {userData.user.hasAdminAccess && (
          <>
            <DeviceParameter
              label="Network RSSI"
              value={device.networkRSSI}
              Icon={RSSIIcon}
            />
            <DeviceParameter
              label="Current firmware version"
              value={
                (
                  Utils.getByID(device.firmwareID, userData.firmwares) ??
                  userData.firmwares[0]
                ).version
              }
              Icon={FirmwareIcon}
            />
            {device.targetFirmwareID !== null ? (
              <DeviceParameter
                label="Target firmware version"
                value={
                  (
                    Utils.getByID(device.targetFirmwareID, userData.firmwares) ??
                    userData.firmwares[0]
                  ).version
                }
                Icon={FirmwareUpdateIcon}
              />
            ) : null}
          </>
        )}
        <DeviceParameter
          label="Latest temperature"
          value={lastMeasurement !== null && lastMeasurement.temperature !== null ? lastMeasurement.temperature : 'Unknown'}
          fixedDisplay
          Icon={TemperatureIcon}
          unit={lastMeasurement !== null && lastMeasurement.temperature !== null ? 'C' : undefined}
        />  
        <DeviceParameter
          label="Latest humidity"
          value={lastMeasurement !== null && lastMeasurement.humidity !== null ? lastMeasurement.humidity : 'Unknown'}
          fixedDisplay
          Icon={HumidityIcon}
          unit={lastMeasurement !== null && lastMeasurement.humidity !== null ? '%' : undefined}
        />
        <DeviceParameter
          label="Daily catch"
          value={lastMeasurement !== null && lastMeasurement.detectionCountDelta !== null ? lastMeasurement.detectionCountDelta : 'Unknown'}
          Icon={DetectionCountDeltaIcon}
        />
      </div>

      {userData.user.hasAdminAccess && (
        <>
          <h2>Configuration</h2>
          <h3>Edit device configuration parameters</h3>
          <div className={styles.optionContainer}>
            <div className={styles.optionRow}>
              <SelectOption
                label="Location configuration"
                value={location}
                onChange={setLocation}
                options={userData.locationConfigs.map((l) => {
                  return {
                    id: l.id,
                    name: Utils.locationConfigDisplayName(l),
                  };
                })}
                highlighted={location !== device.locationConfigID}
              />
              <SelectOption
                label="Operation configuration"
                value={config}
                onChange={setConfig}
                options={userData.operationConfigs.map((c) => {
                  return {
                    id: c.id,
                    name: Utils.operationConfigDisplayName(c),
                  };
                })}
                highlighted={config !== device.operationConfigID}
              />
            </div>
            <div className={styles.optionRow}>
              <SelectOption
                label="Crop type"
                value={crop}
                onChange={setCrop}
                options={userData.crops}
                highlighted={crop !== device.cropID}
              />
              <SelectOption
                label="Pest type"
                value={pest}
                onChange={setPest}
                options={userData.pests}
                highlighted={pest !== device.pestID}
              />
            </div>

            <div className={styles.optionRow}>
              <SelectOption
                label="Commission status"
                value={commissionStatus}
                onChange={setCommissionStatus}
                options={userData.commissionStatuses}
                highlighted={commissionStatus !== device.commissionStatusID}
              />
              <SelectOption
                label="Target firmware version"
                value={targetFirmware !== null ? targetFirmware : 0}
                onChange={setTargetFirmware}
                options={[{ id: 0, name: "Not set" }].concat(
                  userData.firmwares
                    .filter((f) => f.id !== device.firmwareID)
                    .map((f) => {
                      return { id: f.id, name: Utils.firmwareDisplayName(f) };
                    })
                )}
                highlighted={targetFirmware !== device.targetFirmwareID}
              />
            </div>
          </div>

          <div className={styles.actionContainer}>
            <Button
              className={styles.button}
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={onDiscardChanges}
            >
              Discard changes
            </Button>

            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              disableElevation
              onClick={onApplyChanges}
            >
              Apply changes
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

import React, { useState, useContext } from 'react'
import { Button, TextField, Modal, Fade, Backdrop } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MemberDisplay } from './MemberDisplay'
import { User, UserData } from 'src/common/types'
import { GlobalContext } from 'src/App'

import styles from './TeamEditor.module.scss'
import { API } from 'src/api'

export const TeamEditor: React.FC<{ userData: UserData }> = ({ userData }) => {
  const { updateUserData, showToast, setConfirmModal, showConfirmModal } = useContext(GlobalContext)
  const [inviteEmail, setInviteEmail] = useState('')
  const [inviteName, setInviteName] = useState('')

  const [userToDelete, setUserToDelete] = useState<User>()

  async function onInvite() {
    if (inviteEmail === '' || inviteName === '') {
      showToast('warning', 'Both fields are required')
      return
    }
    const r = await API.Account.createUser({ email: inviteEmail, name: inviteName })
    setInviteEmail('')
    setInviteName('')
    if (r.result === 'ok') {
      showToast('success', 'Invitation sent')
      updateUserData()
    } else {
      showToast('error', 'Failed to send invite: ' + r.message)
    }
  }

  async function onRoleChange(
    user: User,
    role: { hasAdminAccess: boolean; hasWriteAccess: boolean }
  ) {
    const r = await API.Account.updateUser({ userID: user.id, ...role })
    updateUserData()
    if (r.result === 'ok') {
      showToast('success', 'User role updated for ' + user.name)
    } else {
      showToast('error', 'Failed to update user role: ' + r.message)
    }
  }

  async function deleteUser() {
    if (!userToDelete) {
      return
    }
    const r = await API.Account.deleteUser({ userID: userToDelete.id })
    setUserToDelete(undefined)
    updateUserData()
    if (r.result === 'ok') {
      showToast('success', 'User deleted: ' + userToDelete.name)
    } else {
      showToast('error', 'Failed to delete user: ' + r.message)
    }
  }

  async function onDelete(user: User) {
    setUserToDelete(user)
    setConfirmModal({
      open: true,
      title: 'Delete user',
      text: 'Are you sure you want to permanently delete this user and all of their history?',
      info: 'Custom filters will be re-assigned to an administrator.',
      cancelLabel: 'Cancel',
      actionLabel: 'Delete',
      action: () => deleteUser(),
    })
  }

  return (
    <div className={styles.container}>
      <h1>Your team</h1>
      <h3>View and edit member account details</h3>
      <div className={styles.memberList}>
        <MemberDisplay
          user={userData.user}
          current={userData.user}
          onRoleChange={onRoleChange}
          onDelete={onDelete}
        />
        {userData.team.map((user, id) => (
          <MemberDisplay
            key={id}
            user={user}
            current={userData.user}
            onRoleChange={onRoleChange}
            onDelete={onDelete}
          />
        ))}
      </div>
      {userData.user.hasAdminAccess ? (
        <>
          <h2>Invite new members</h2>
          <h3>Login credentials are generated automatically and sent via email</h3>
          <div className={styles.invite}>
            <div className={styles.inputContainer}>
              <TextField
                className={styles.input}
                label='Email'
                type='email'
                color='secondary'
                variant='outlined'
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                size='small'
              />
              <TextField
                className={styles.input}
                label='Name'
                type='text'
                color='secondary'
                variant='outlined'
                value={inviteName}
                onChange={(e) => setInviteName(e.target.value)}
                size='small'
              />
            </div>
            <Button
              className={styles.button}
              variant='contained'
              disableElevation
              color='primary'
              onClick={onInvite}
            >
              Invite member
            </Button>
          </div>
        </>
      ) : null}
    </div>
  )
}

import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Collapse } from '@material-ui/core'
import {
  Schedule as ActivityIcon,
  InfoOutlined as EventIcon,
  PriorityHighOutlined as ErrorIcon,
  NavigateNext as DetailsIcon,
} from '@material-ui/icons'
import * as Utils from 'src/common/utils'
import * as Types from 'src/common/types'

import styles from './DeviceHistoryDisplay.module.scss'

interface Props {
  userData: Types.UserData
  event: Types.DeviceEvent
}

export const DeviceHistoryDisplay: React.FC<Props> = ({ userData, event }) => {
  const [detailsVisible, setDetailsVisible] = useState(false)

  return (
    <div className={styles.deviceHistoryDisplay} onClick={() => setDetailsVisible(!detailsVisible)}>
      <div className={styles.summary}>
        <div className={styles.block}>
          <EventIcon className={styles.icon} />
          {Utils.getByID(event.eventID, userData.deviceEventTypes)?.name}
        </div>
        {event.errorID ? (
          <div className={styles.block}>
            <ErrorIcon className={styles.icon} />
            {'Error - ' + Utils.getByID(event.errorID, userData.deviceErrorTypes)?.name}
          </div>
        ) : null}
        <div className={styles.block}>
          <ActivityIcon className={styles.icon} />
          {Utils.getDateTimeString(event.timestamp)}
        </div>
      </div>
      <Collapse className={styles.details} in={detailsVisible}>
        <div className={styles.content}>
          <DetailsIcon className={styles.icon} />
          {event.comment || 'no additional details available'}
        </div>
      </Collapse>
    </div>
  )
}

import React from 'react'
import { Tooltip } from '@material-ui/core'
import { User } from 'src/common/types'
import * as Utils from 'src/common/utils'

import styles from './Member.module.scss'

interface MemberProps {
  user: User
  onClick: () => void
  enabled?: boolean
}

export const Member: React.FC<MemberProps> = ({ user, onClick, enabled }) => {
  return (
    <Tooltip arrow title={<span className={styles.tooltip}>{user.name}</span>} placement='bottom'>
      <div onClick={onClick} className={`${styles.member} ${enabled ? styles.enabled : null}`}>
        <span>{Utils.getInitials(user)}</span>
      </div>
    </Tooltip>
  )
}

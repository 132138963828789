import { config } from "src/config";

let scheme = "https";
let host = "core.g2.smapplab.com/api";
let basePath = config.production ? "/prod/admin" : "/dev/admin";

// Override API address for local testing
if (!config.built && config.useLocalAPI) {
  scheme = "http";
  host = "localhost:5000";
  basePath = "/admin";
}

function construct(endpoint: string) {
  return `${scheme}://${host}${basePath}${endpoint}`;
}

const urls = {
  login: construct("/login"),
  logout: construct("/logout"),
  user: construct("/user"),
  userData: construct("/user-data"),
  devices: construct("/devices"),
  savedFilter: construct("/saved-filter"),
  mapsApiKey: construct("/maps-api-key"),
  deviceDetails: construct("/device-details"),
  deviceLastMeasurements: construct("/device-last-measurements"),
  getDeviceMeasurements: construct("/devices/measurements"),
  getDeviceMeasurementsById: (id: number) =>
    construct(`/devices/${id}/measurements`),
  deviceEvents: construct("/device-events"),
  deviceActivity: construct("/device-activity"),
  imageUrl: construct("/image-url"),
  overrideDetectionCountUrl: construct("/override-detection-count"),
  clearDetectionCountUrl: construct("/clear-detection-count"),
  deviceDiagnosticWindow: construct("/device-diagnostic-window"),
};

export default urls;

import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { NavigateBeforeRounded, NavigateNextRounded } from "@material-ui/icons";
import { GlobalContext } from "src/App";
import * as Types from "src/common/types";
import * as Utils from "src/common/utils";
import { CollapsibleCard } from "src/components";
import { API } from "src/api";
import styles from "./DeviceImageView.module.scss";

interface Props {
  userData: Types.UserData;
  device: Types.Device;
}

export const DeviceImageView: React.FC<Props> = ({ userData, device }) => {
  const { showToast, setConfirmModal } = useContext(GlobalContext);
  const [index, setIndex] = useState(0);
  const [imageDate, setImageDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [processedImageURL, setProcessedImageURL] = useState<string | null>(
    null
  );
  const [originalDetectionCount, setOriginalDetectionCount] = useState<
    number | null
  >(null);
  const [originalDetectionCountDelta, setOriginalDetectionCountDelta] =
    useState<number | null>(null);
  const [detectionCount, setDetectionCount] = useState<number | null>(null);
  const [detectionCountDelta, setDetectionCountDelta] = useState<number | null>(
    null
  );
  const [olderAvailable, setOlderAvailable] = useState(true);

  useEffect(() => {
    if (index < 0) setIndex(0);
    updateImage();
  }, [index]);

  async function updateImage() {
    setLoading(true);
    const r = await API.Device.getImageUrl(device.imei, index);
    if (r.result === "ok") {
      if (r.message.timestamp !== null)
        setImageDate("Time: " + Utils.getDateTimeString(r.message.timestamp));
      setImageURL(r.message.imageURL);
      setProcessedImageURL(r.message.processedImageURL);
      setOriginalDetectionCount(r.message.detectionCount);
      setOriginalDetectionCountDelta(r.message.detectionCountDelta);
      setDetectionCount(r.message.detectionCount);
      setDetectionCountDelta(r.message.detectionCountDelta);
      setOlderAvailable(r.message.olderAvailable);
      console.log(r);
    }
    setLoading(false);
  }

  async function onUpdateDetectionCount() {
    if (detectionCount === null || detectionCount < 0) {
      return;
    }

    const r = await API.Device.overrideDetectionCount({
      imei: device.imei,
      index: index,
      detectionCount: detectionCount,
    });
    if (r.result === "ok") {
      setOriginalDetectionCount(r.message.detectionCount);
      setOriginalDetectionCountDelta(r.message.detectionCountDelta);
      setDetectionCount(r.message.detectionCount);
      setDetectionCountDelta(r.message.detectionCountDelta);
      showToast("success", "Detection count updated for " + device.imei);
    } else {
      showToast("error", "Failed to update detection count: " + device.imei);
    }
  }

  function onClearDevice() {
    setConfirmModal({
      open: true,
      title: 'Clear device',
      text: 'Are you sure you want to create a new measurement with zero detection count?',
      action: clearDevice,
      actionLabel: 'Create measurement',
    })
  }

  async function clearDevice() {
    const r = await API.Device.clearDetectionCount({
      imei: device.imei,
      index: index,
    })
    if (r.result === 'ok') {
      showToast('success', 'Device detection count cleared')
      updateImage()
    } else {
      showToast('error', 'Failed to clear detection count: ' + r.message)
    }
  }

  return (
    <div className={styles.collapsibleContainer}>
      <CollapsibleCard
        title="Uploaded images"
        subtitle={imageDate}
        openByDefault
        actionSet={
          <>
            <Button
              className={styles.button}
              variant="outlined"
              color="secondary"
              disableElevation
              disabled={loading || !olderAvailable}
              onClick={() => setIndex(index + 1)}
            >
              <NavigateBeforeRounded className={styles.icon} />
            </Button>
            <Button
              className={styles.centerButton}
              variant="outlined"
              color="secondary"
              disableElevation
              disabled={loading}
              onClick={() => setIndex(0)}
            >
              Latest
            </Button>
            <Button
              className={styles.button}
              variant="outlined"
              color="secondary"
              disabled={loading || index === 0}
              disableElevation
              onClick={() => setIndex(index - 1)}
            >
              <NavigateNextRounded className={styles.icon} />
            </Button>
          </>
        }
      >
        <div className={styles.imageDisplay}>
          {loading ? (
            <div className={styles.loadingIndicator}>
              <CircularProgress />
            </div>
          ) : imageURL !== null ? (
            <img src={imageURL} />
          ) : (
            <div className={styles.placeholder}>No image available</div>
          )}
        </div>
      </CollapsibleCard>
      {userData.user.hasAdminAccess && (
        <>
          <div className={styles.cardDivider}></div>

          <CollapsibleCard
            title="Processed Image"
            subtitle={imageDate}
            openByDefault
            actionSet={
              <div className={styles.actionContainer}>
                <TextField
                  className={styles.input}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  type="number"
                  label="Detection count"
                  value={detectionCount ?? ""}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  InputLabelProps={{ shrink: detectionCount !== null }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const parsed = parseInt(e.target.value, 10);
                    if (isNaN(parsed) || parsed < 0) {
                      return;
                    }
                    setDetectionCount(parsed);

                    // Calculate live delta
                    const d = parsed - (originalDetectionCount ?? 0);
                    const dd = (originalDetectionCountDelta ?? 0) + d;
                    const newDelta = dd >= 0 ? dd : 0;
                    setDetectionCountDelta(newDelta);
                  }}
                />
                <TextField
                  className={styles.deltaTextfield}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  type="number"
                  label="Delta"
                  disabled={true}
                  value={detectionCountDelta ?? ""}
                  InputLabelProps={{ shrink: detectionCountDelta !== null }}
                />
                <Button
                  className={styles.updateButton}
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  disabled={detectionCount === null}
                  onClick={() => {
                    setDetectionCount(originalDetectionCount);
                    setDetectionCountDelta(originalDetectionCountDelta);
                  }}
                >
                  Reset
                </Button>
                <Button
                  className={styles.updateButton}
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={detectionCount === null}
                  onClick={onUpdateDetectionCount}
                >
                  {originalDetectionCount === null ? "Validate" : "Update"}
                </Button>
                <Button
                  className={styles.updateButton}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onClearDevice}
                > 
                  Clear device
                </Button>
              </div>
            }
          >
            <div className={styles.imageDisplay}>
              {loading ? (
                <div className={styles.loadingIndicator}>
                  <CircularProgress />
                </div>
              ) : processedImageURL !== null ? (
                <img src={processedImageURL} />
              ) : (
                <div className={styles.placeholder}>No image available</div>
              )}
            </div>
          </CollapsibleCard>
        </>
      )}
    </div>
  );
};

import React, { useContext } from 'react'
import { Button, Modal, Fade, Backdrop } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Menu } from 'src/components'
import { GlobalContext } from 'src/App'
import styles from './PageBase.module.scss'

export const PageBase: React.FC = ({ children }) => {
  const { confirmModal, showConfirmModal } = useContext(GlobalContext)

  function onConfirmModalClose() {
    showConfirmModal(false)
    confirmModal?.onCancel?.()
  }

  return (
    <div className={styles.container}>
      <Menu />
      <div className={styles.content}>{children}</div>
      {confirmModal ? (
        <Modal
          open={confirmModal.open}
          onClose={onConfirmModalClose}
          className={styles.modalContainer}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 250,
          }}
        >
          <Fade in={confirmModal.open}>
            <div className={styles.modal}>
              <h1>{confirmModal.title}</h1>
              <p>{confirmModal.text}</p>

              {confirmModal.info ? (
                <Alert className={styles.info} severity='info'>
                  {confirmModal.info}
                </Alert>
              ) : null}

              <div className={styles.buttons}>
                <Button
                  onClick={() => {
                    showConfirmModal(false)
                    confirmModal.action()
                  }}
                  className={`${styles.button} ${styles.delete}`}
                >
                  {confirmModal.actionLabel ?? 'Proceed'}
                </Button>
                <Button onClick={onConfirmModalClose} variant='outlined' className={styles.button}>
                  {confirmModal.cancelLabel ?? 'Cancel'}
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  )
}

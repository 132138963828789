import * as React from "react";
import styles from "./index.module.scss";
import * as Types from "src/common/types";
import { GlobalContext } from "src/App";
import DeviceMarker from "./DeviceMarker";
import GoogleMapReact, { MapOptions } from "google-map-react";
import { CollapsibleCard } from "src/components";

const MAP_OPTIONS: MapOptions = {
  clickableIcons: false,
  mapTypeId: "satellite",
  rotateControl: false,
  fullscreenControl: false,
};
interface DeviceMapProps {
  devices: Types.Device[];
  userData: Types.UserData;
}

const DeviceMap: React.VFC<DeviceMapProps> = ({ devices, userData }) => {
  const { mapsApiKey } = React.useContext(GlobalContext);
  const [openMarkerID, setOpenMarkerID] = React.useState<
    Types.Device["id"] | undefined
  >(undefined);

  const knownDevices = React.useMemo(
    () =>
      devices.filter(
        ({ lastCoordinates }) =>
          lastCoordinates?.latitude && lastCoordinates?.longitude
      ),
    [devices]
  );

  const defaultMapCenter = React.useMemo(() => {
    if (knownDevices.length > 0) {
      return {
        lat:
          knownDevices.reduce((a, b) => a + b.lastCoordinates!.latitude, 0) /
          knownDevices.length,
        lng:
          knownDevices.reduce((a, b) => a + b.lastCoordinates!.longitude, 0) /
          knownDevices.length,
      };
    } else {
      return { lat: 47.1611615, lng: 19.5057541 };
    }
  }, [knownDevices]);

  const defaultZoom = React.useMemo(() => {
    if (knownDevices.length > 0) {
      if (knownDevices.length === 1) return 15;
      else return 0;
    } else return 7;
  }, [knownDevices]);

  const [mapCenter, setMapCenter] = React.useState<{
    lng: number;
    lat: number;
  }>(defaultMapCenter);
  const [mapZoom, setMapZoom] = React.useState<number>(defaultZoom);

  const markerList = knownDevices.map((device) => (
    <DeviceMarker
      key={device.id}
      isOpen={openMarkerID === device.id}
      onClose={() => {
        setOpenMarkerID(undefined);
      }}
      onOpen={() => {
        setOpenMarkerID(device.id);
      }}
      userData={userData}
      device={device}
      lat={device.lastCoordinates?.latitude ?? 0}
      lng={device.lastCoordinates?.longitude ?? 0}
    />
  ));

  return (
    <CollapsibleCard title="Device Map" subtitle="">
      <div className={styles.container}>
        {knownDevices.length === 0 ? (
          <div className={styles.placeholder}>
            Not able to locate any device
          </div>
        ) : !mapsApiKey ? (
          <div className={styles.placeholder}>No google maps API key found</div>
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: mapsApiKey,
            }}
            options={MAP_OPTIONS}
            defaultCenter={mapCenter}
            defaultZoom={mapZoom}
          >
            {markerList}
          </GoogleMapReact>
        )}
      </div>
    </CollapsibleCard>
  );
};
export default DeviceMap;

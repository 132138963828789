import React, { useState, useEffect, ChangeEvent, useContext } from 'react'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Collapse,
} from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { API } from 'src/api'
import { GlobalContext } from 'src/App'
import * as Types from 'src/common/types'
import * as Utils from 'src/common/utils'
import styles from './DeviceFilter.module.scss'

interface SelectOptionProps {
  label: string
  options: { id: number; name: string }[]
  value: number[]
  onChange: (value: number[]) => void
}

const SelectOption: React.FC<SelectOptionProps> = ({
  label,
  options,
  value,
  onChange,
}) => {
  function handleChange(e: React.ChangeEvent<{ value: unknown }>) {
    const selected = e.target.value as unknown[]
    const values: number[] = []
    selected.forEach((i) => {
      if (i !== 'any') {
        values.push(i as number)
      }
    })
    if (selected.includes('any') && value.length > 0) {
      onChange([])
      return
    }
    onChange(values)
  }

  return (
    <FormControl
      size='small'
      variant='outlined'
      color='secondary'
      className={styles.selectOption}
    >
      <InputLabel id={'select-option-label-' + label}>{label}</InputLabel>
      <Select
        id={'select-option-' + label}
        labelId={'select-option-label-' + label}
        label={label}
        multiple
        onChange={handleChange}
        value={value.length === 0 ? ['any'] : value}
        className={styles.selectInput}
      >
        {[
          <MenuItem key={-1} value='any' selected={value.length === 0}>
            Any
          </MenuItem>,
        ].concat(
          options.map((o, idx) => (
            <MenuItem key={idx} value={o.id} selected={value.includes(o.id)}>
              {o.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
}

interface TextOptionProps {
  label: string
  value: string
  onChange: (value: string) => void
  large?: boolean
  error?: boolean
}

const TextOption: React.FC<TextOptionProps> = ({
  label,
  value,
  large,
  error,
  onChange,
}) => {
  return (
    <div className={`${styles.textOption} ${large ? styles.large : null}`}>
      <TextField
        label={label}
        value={value}
        variant='outlined'
        color='secondary'
        size='small'
        error={error}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value as string)
        }
        className={`${styles.textOptionInput} ${error ? styles.error : null}`}
      />
    </div>
  )
}

interface Props {
  userData: Types.UserData
}

export const DeviceFilter: React.FC<Props> = ({ userData }) => {
  const [uids, setUids] = useState<string>('')
  const [imeis, setImeis] = useState<string>('')
  const [locationIDList, setLocationIDList] = useState<number[]>([])
  const [cropIDList, setCropIDList] = useState<number[]>([])
  const [pestIDList, setPestIDList] = useState<number[]>([])
  const [commissionStatusIDList, setCommissionStatusIDList] = useState<
    number[]
  >([])
  const [configIDList, setConfigIDList] = useState<number[]>([])
  const [savedFiltersVisible, setSavedFiltersVisible] = useState(false)
  const [savedFilterName, setSavedFilterName] = useState('')

  const {
    deviceFilter,
    setDeviceFilter,
    showToast,
    updateUserData,
    setConfirmModal,
  } = useContext(GlobalContext)

  useEffect(() => {
    loadFilter(deviceFilter, true)
  }, [])

  function resetFilter() {
    setUids('')
    setImeis('')
    setLocationIDList([])
    setCropIDList([])
    setPestIDList([])
    setCommissionStatusIDList([])
    setConfigIDList([])
    setDeviceFilter({
      uids: [],
      imeis: [],
      locations: [],
      crops: [],
      pests: [],
      commissionStatuses: [],
      configs: [],
    })
  }

  function applyFilter() {
    if (validateUids() && validateImeis()) {
      setDeviceFilter({
        uids: Utils.splitList(uids).map((x) => x.toUpperCase()),
        imeis: Utils.splitList(imeis),
        locations: locationIDList,
        crops: cropIDList,
        pests: pestIDList,
        commissionStatuses: commissionStatusIDList,
        configs: configIDList,
      })
    }
  }

  async function saveFilter() {
    const r = await API.Account.createSavedFilter({
      name: savedFilterName,
      filterParams: {
        uids: Utils.splitList(uids).map((x) => x.toUpperCase()),
        imeis: Utils.splitList(imeis),
        locations: locationIDList,
        crops: cropIDList,
        pests: pestIDList,
        commissionStatuses: commissionStatusIDList,
        configs: configIDList,
      },
    })
    if (r.result === 'ok') {
      showToast('success', 'Custom filter saved')
      setSavedFilterName('')
      updateUserData()
    } else {
      showToast('error', 'Failed to create custom filter: ' + r.message)
    }
  }

  function loadFilter(filter: Types.DeviceFilter, apply?: boolean) {
    setUids(filter.uids.join(', '))
    setImeis(filter.imeis.join(', '))
    setLocationIDList(filter.locations)
    setCropIDList(filter.crops)
    setPestIDList(filter.pests)
    setCommissionStatusIDList(filter.commissionStatuses)
    setConfigIDList(filter.configs)
    if (apply) setDeviceFilter(filter)
  }

  function onDeleteFilter(id: number) {
    setConfirmModal({
      open: true,
      title: 'Delete custom filter',
      text: 'Are you sure you want to permanently delete this custom filter?',
      action: () => deleteFilter(id),
      actionLabel: 'Delete',
    })
  }

  async function deleteFilter(id: number) {
    const r = await API.Account.deleteSavedFilter({
      filterID: id,
    })
    if (r.result === 'ok') {
      showToast('success', 'Custom filter deleted')
      updateUserData()
    } else {
      showToast('error', 'Failed to delete custom filter: ' + r.message)
    }
  }

  function validateUids(): boolean {
    if (uids === '') return true
    return Utils.matchList(Utils.splitList(uids), /^[sS][mM][pP]-[0-9]{4}$/)
  }

  function validateImeis(): boolean {
    if (imeis === '') return true
    return Utils.matchList(Utils.splitList(imeis), /^[0-9]{16}$/)
  }

  function getFilterParamLengthSum(f: Types.DeviceFilter) {
    return (
      f.uids.length +
      f.imeis.length +
      f.locations.length +
      f.crops.length +
      f.pests.length +
      f.commissionStatuses.length +
      f.configs.length
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.texts}>
          <h1>Filter</h1>
          <h3>Filter displayed device list</h3>
        </span>

        <span className={styles.buttonContainer}>
          <Button
            size='small'
            disableElevation
            className={styles.button}
            variant='outlined'
            color='secondary'
            onClick={resetFilter}
          >
            Reset
          </Button>
          <Button
            size='small'
            disableElevation
            className={styles.button}
            variant='contained'
            color='primary'
            onClick={applyFilter}
          >
            Apply filter
          </Button>
        </span>
      </div>
      <div className={styles.optionList}>
        <TextOption
          label='UIDs'
          value={uids}
          onChange={setUids}
          error={!validateUids()}
        />
        <TextOption
          label='IMEIs'
          value={imeis}
          onChange={setImeis}
          large
          error={!validateImeis()}
        />
        <SelectOption
          label='Location'
          value={locationIDList}
          onChange={setLocationIDList}
          options={userData.locationConfigs.map((c) => {
            return { id: c.id, name: `${c.country} (${c.timezone})` }
          })}
        />
        <SelectOption
          label='Crop'
          value={cropIDList}
          onChange={setCropIDList}
          options={userData.crops}
        />
        <SelectOption
          label='Pest'
          value={pestIDList}
          onChange={setPestIDList}
          options={userData.pests}
        />
        <SelectOption
          label='Commission status'
          value={commissionStatusIDList}
          onChange={setCommissionStatusIDList}
          options={userData.commissionStatuses}
        />
        <SelectOption
          label='Configuration'
          value={configIDList}
          onChange={setConfigIDList}
          options={userData.operationConfigs}
        />
      </div>
      {userData.user.hasWriteAccess ? (
        <>
          <h2>Save as custom filter</h2>
          <h3>Create a reusable filter (team accessible)</h3>
          <div className={styles.saveInputContainer}>
            <FormControl size='small' variant='outlined' color='secondary'>
              <TextField
                className={styles.input}
                variant='outlined'
                size='small'
                color='secondary'
                label='Filter name'
                value={savedFilterName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSavedFilterName(e.target.value)
                }}
              />
            </FormControl>
            <Button
              className={styles.button}
              variant='outlined'
              disabled={savedFilterName.trim() === ''}
              onClick={saveFilter}
            >
              Save
            </Button>
          </div>
        </>
      ) : null}

      <div
        className={styles.savedFilterButton}
        onClick={() => setSavedFiltersVisible(!savedFiltersVisible)}
      >
        <h2>Custom filters</h2>
        {savedFiltersVisible ? (
          <ExpandLess className={styles.icon} />
        ) : (
          <ExpandMore className={styles.icon} />
        )}
      </div>
      <Collapse in={savedFiltersVisible} className={styles.savedFilterCollapse}>
        <div className={styles.content}>
          {userData.savedFilters.length === 0 ? (
            <div className={styles.placeholder}>
              No custom filters available
            </div>
          ) : (
            userData.savedFilters.map((f, idx) => (
              <div key={'saved-filter-' + idx} className={styles.item}>
                <div className={styles.summary}>
                  <span className={styles.title}>
                    <h1>{f.name}</h1>
                    <h2>{`(by ${
                      Utils.getUserByID(
                        f.userID,
                        [userData.user].concat(userData.team)
                      )?.name ?? 'unknown'
                    })`}</h2>
                  </span>
                  <span>
                    {userData.user.hasWriteAccess ? (
                      <Button
                        disableElevation
                        size='small'
                        variant='outlined'
                        color='secondary'
                        className={styles.button}
                        onClick={() => onDeleteFilter(f.id)}
                      >
                        Delete
                      </Button>
                    ) : null}

                    <Button
                      disableElevation
                      size='small'
                      variant='outlined'
                      color='secondary'
                      className={styles.button}
                      onClick={() => loadFilter(f.filterParams)}
                    >
                      Load
                    </Button>
                    <Button
                      disableElevation
                      size='small'
                      variant='contained'
                      color='primary'
                      className={styles.button}
                      onClick={() => loadFilter(f.filterParams, true)}
                    >
                      Apply
                    </Button>
                  </span>
                </div>
                {getFilterParamLengthSum(f.filterParams) === 0 ? null : (
                  <div className={styles.filterOptions}>
                    {f.filterParams.uids.length > 0 ? (
                      <div className={styles.filterOption}>
                        {f.filterParams.uids.length} UID(s)
                      </div>
                    ) : null}
                    {f.filterParams.imeis.length > 0 ? (
                      <div className={styles.filterOption}>
                        {f.filterParams.imeis.length} IMEI(s)
                      </div>
                    ) : null}
                    {f.filterParams.locations.length > 0 ? (
                      <div className={styles.filterOption}>
                        {f.filterParams.locations.length} location(s)
                      </div>
                    ) : null}
                    {f.filterParams.crops.length > 0 ? (
                      <div className={styles.filterOption}>
                        {f.filterParams.crops.length} crop(s)
                      </div>
                    ) : null}
                    {f.filterParams.pests.length > 0 ? (
                      <div className={styles.filterOption}>
                        {f.filterParams.pests.length} pest(s)
                      </div>
                    ) : null}
                    {f.filterParams.commissionStatuses.length > 0 ? (
                      <div className={styles.filterOption}>
                        {f.filterParams.commissionStatuses.length} commission
                        status(es)
                      </div>
                    ) : null}
                    {f.filterParams.configs.length > 0 ? (
                      <div className={styles.filterOption}>
                        {f.filterParams.configs.length} operation
                        configuration(s)
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Collapse>
    </div>
  )
}

import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/App";
import { CircularProgress } from "@material-ui/core";
import { DeviceOverview } from "./DeviceOverview/DeviceOverview";
import { DeviceActivity } from "./DeviceActivity/DeviceActivity";
import { DeviceHistory } from "./DeviceHistory/DeviceHistory";
import { DeviceImageView } from "./DeviceImageView/DeviceImageView";
import * as Types from "src/common/types";
import styles from "./DevicePage.module.scss";
import { useHistory } from "react-router";
import { API } from "src/api";
import DeviceMeasurementsById from "./DeviceMeasurementsById";
import { DeviceDiagnostics } from "./DeviceDiagnostics/DeviceDiagnostics";

export const DevicePage: React.FC = () => {
  const { userData, showToast, updateDeviceList, updateUserData } =
    useContext(GlobalContext);
  const history = useHistory();
  const [device, setDevice] = useState<Types.Device | null>(null);
  const [lastMeasurement, setLastMeasurement] =
    useState<Types.Measurement | null>(null);
  const [targetImei, setTargetImei] = useState<string | null>(null);

  useEffect(() => {
    let imei = history.location.hash;
    imei = imei.substring(1);
    setTargetImei(imei);
  }, [history.location]);

  useEffect(() => {
    async function getDevice(imei: string) {
      const r = await API.Device.deviceDetails({ imeis: [imei] });
      if (r.result === "ok") {
        await getLastMeasurement(r.message[0].id);
        setDevice(r.message[0]);
      } else {
        history.replace("/home");
      }
    }
    async function getLastMeasurement(deviceIDs: number) {
      const r = await API.Device.deviceLastMeasurements({
        deviceIDs: [deviceIDs],
      });
      if (r.result === "ok" && r.message.length > 0) {
        setLastMeasurement(r.message[0]);
      }
    }

    if (targetImei) {
      getDevice(targetImei);
    }
  }, [targetImei]);

  async function updateDevice(data: {
    [imei: string]: Types.DeviceUpdateData;
  }) {
    const r = await API.Device.updateDevices(data);
    if (r.result === "ok") {
      setDevice(r.message[0]);
      updateDeviceList();
      showToast("success", "Device parameters updated for " + r.message[0].uid);
    } else {
      showToast("error", "Failed to update device parameters: " + r.message);
    }
  }

  if (!userData || !device) {
    return (
      <div className={styles.loadingIndicator}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DeviceOverview
        userData={userData}
        device={device}
        lastMeasurement={lastMeasurement}
        updateDevice={updateDevice}
      />
      <DeviceMeasurementsById id={device.id} />
      <DeviceImageView userData={userData} device={device} />
      {/* <DeviceDiagnostics device={device} /> */}
      <DeviceActivity device={device} />
      <DeviceHistory userData={userData} device={device} />
    </div>
  );
};

import React, { useContext, useEffect } from "react";
import { GlobalContext } from "src/App";
import { CircularProgress } from "@material-ui/core";
import { TeamEditor } from "./TeamEditor/TeamEditor";
import { TeamHistory } from "./TeamHistory/TeamHistory";

import styles from "./TeamPage.module.scss";
import { Redirect } from "react-router-dom";

export const TeamPage: React.FC = () => {
  const { userData, updateUserData } = useContext(GlobalContext);

  useEffect(() => {
    updateUserData();
  }, []);

  if (!userData) {
    return (
      <div className={styles.loadingIndicator}>
        <CircularProgress />
      </div>
    );
  }
  if (!userData.user.hasAdminAccess) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={styles.container}>
      <TeamEditor userData={userData} />
      <TeamHistory userData={userData} />
    </div>
  );
};

import React, { useState, useEffect } from 'react'
import { Collapse, Button } from '@material-ui/core'
import { UnfoldLessRounded, UnfoldMoreRounded } from '@material-ui/icons'
import styles from './CollapsibleCard.module.scss'

interface Props {
  title: string
  subtitle: string
  openByDefault?: boolean
  actionSet?: React.ReactElement
}

export const CollapsibleCard: React.FC<Props> = ({
  children,
  title,
  subtitle,
  actionSet,
  openByDefault,
}) => {
  const [collapsed, setCollapsed] = useState(openByDefault === undefined)

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <span>
          <h1>{title}</h1>
          <h3>{subtitle}</h3>
        </span>
        {actionSet ? <div>{actionSet}</div> : null}
        <Button
          variant='outlined'
          className={styles.collapseButton}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <UnfoldMoreRounded /> : <UnfoldLessRounded />}
        </Button>
      </div>
      <Collapse className={styles.collapse} in={!collapsed}>
        <div className={styles.content}>{children}</div>
      </Collapse>
    </div>
  )
}

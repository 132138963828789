import React, { useState, useContext } from 'react'
import { TextField, Button, Collapse } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { SHA256 } from 'crypto-js'
import { GlobalContext } from 'src/App'
import { API } from 'src/api'

import styles from './LoginPage.module.scss'

export const LoginPage: React.FC = () => {
  const { updateUserData } = useContext(GlobalContext)
  const [interactable, setInteractable] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  function onEmailChange(value: string) {
    setEmail(value)
    setError(false)
  }

  function onPasswordChange(value: string) {
    setPassword(value)
    setError(false)
  }

  async function handleLogin() {
    if (email === '' || password === '') {
      setError(true)
      return
    }
    setInteractable(false)
    const passwordHash = SHA256(password).toString()
    const r = await API.Account.login({ email, passwordHash })
    if (r.result === 'error') {
      setError(true)
      setInteractable(true)
    } else {
      updateUserData()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <img
          draggable={false}
          className={styles.logo}
          src={`${process.env.PUBLIC_URL}/svg/logo-h-black.svg`}
        />
        <TextField
          className={styles.input}
          variant='outlined'
          color='secondary'
          label='Email'
          placeholder='name@company.com'
          type='email'
          disabled={!interactable}
          error={error}
          value={email}
          onChange={(e) => onEmailChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleLogin()
          }}
        />
        <TextField
          className={styles.input}
          variant='outlined'
          color='secondary'
          label='Password'
          placeholder='••••••••••••'
          type='password'
          disabled={!interactable}
          error={error}
          value={password}
          onChange={(e) => onPasswordChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleLogin()
          }}
        />
        <Button
          className={styles.button}
          disableElevation
          disabled={!interactable}
          variant='outlined'
          color='primary'
          onClick={handleLogin}
        >
          Log in
        </Button>
      </div>
      <Collapse in={error}>
        <Alert className={styles.alert} severity='error'>
          {'Invalid credentials'}
        </Alert>
      </Collapse>
    </div>
  )
}

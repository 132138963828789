import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useCookies } from "react-cookie";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

import * as Types from "src/common/types";
import * as Pages from "src/pages";
import { API } from "src/api";

export const GlobalContext = React.createContext<Types.ContextType>(
  {} as Types.ContextType
);

function App() {
  const [redirectTarget, setRedirectTarget] = useState<{
    from: string;
    to: string;
  } | null>(null);
  const [userData, setUserData] = useState<Types.UserData>();
  const [devices, setDevices] = useState<Types.Device[]>();
  const [lastMeasurements, setLastMeasurements] =
    useState<Types.Measurement[]>();
  const [deviceFilter, setDeviceFilter] = useState<Types.DeviceFilter>({
    uids: [],
    imeis: [],
    locations: [],
    crops: [],
    pests: [],
    commissionStatuses: [],
    configs: [],
  });
  const [toastText, setToastText] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState<
    "error" | "info" | "warning" | "success"
  >("info");
  const [confirmModal, setConfirmModal] = useState<Types.ConfirmModalParams>();
  const [cookies] = useCookies(["slg2-admin-session-key"]);
  const [mapsApiKey, setMapsApiKey] = React.useState<string | undefined>();

  // Try updating user data (if cookie is present)
  useEffect(() => {
    if (cookies["slg2-admin-session-key"]) {
      updateUserData();
    } else {
      setRedirectTarget({ from: "/home*", to: "/auth" });
    }
  }, [cookies]);

  useEffect(() => {
    if (userData) {
      updateDeviceList();
    }
  }, [userData]);

  React.useEffect(() => {
    async function getMapsApiKey() {
      const res = await API.Account.getMapsApiKey();
      if (res.result === "ok") {
        setMapsApiKey(res.message);
      } else {
        console.log("Error: could not set map api key");
      }
    }
    if (!mapsApiKey && userData) {
      getMapsApiKey();
    }
  }, [userData]);

  function showConfirmModal(open: boolean) {
    if (confirmModal) {
      setConfirmModal({ ...confirmModal, open });
    }
  }

  async function updateUserData() {
    const r = await API.Account.getUserData();
    if (r.result === "ok") {
      setUserData(r.message);
      setRedirectTarget({ from: "/auth", to: "/home" });
    } else {
      setUserData(undefined);
      setRedirectTarget({ from: "/home*", to: "/auth" });
    }
  }

  async function updateDeviceList() {
    const r = await API.Device.deviceList();
    if (r.result === "ok") {
      await updateLastMeasurements(r.message.map((d) => d.id));
      setDevices(r.message);
    } else {
      setDevices(undefined);
      setUserData(undefined);
      setRedirectTarget({ from: "/home*", to: "/auth" });
    }
  }

  async function updateLastMeasurements(ids: number[]) {
    const r = await API.Device.deviceLastMeasurements({ deviceIDs: ids });
    if (r.result === "ok") {
      setLastMeasurements(r.message);
    } else {
      setLastMeasurements(undefined);
    }
  }

  function onToastClosed(event?: React.SyntheticEvent, reason?: string) {
    setToastOpen(false);
  }

  function showToast(
    type: "error" | "info" | "warning" | "success",
    message: string
  ) {
    setToastType(type);
    setToastText(message);
    setToastOpen(true);
  }

  const ctx: Types.ContextType = {
    showToast,
    updateUserData,
    userData,
    updateDeviceList,
    devices,
    lastMeasurements,
    deviceFilter,
    setDeviceFilter,
    confirmModal,
    setConfirmModal,
    showConfirmModal,
    mapsApiKey,
  };

  const appTheme = createMuiTheme({
    palette: {
      primary: { main: "#f4b519" },
      secondary: { main: "#404040" },
    },
    typography: {
      fontFamily: ["Open Sans", "Sans Serif"].join(", "),
    },
  });

  return (
    <>
      <ThemeProvider theme={appTheme}>
        <GlobalContext.Provider value={ctx}>
          <Router>
            <Switch>
              <Redirect exact from="/" to="/home" />
              {redirectTarget ? (
                <Redirect from={redirectTarget.from} to={redirectTarget.to} />
              ) : null}
              <Route path="/home">
                <Pages.PageBase>
                  <Switch>
                    <Route exact path="/home">
                      <Pages.HomePage />
                    </Route>
                    <Route exact path="/home/team">
                      <Pages.TeamPage />
                    </Route>
                    <Route path="/home/device/*">
                      <Pages.DevicePage />
                    </Route>
                  </Switch>
                  <Snackbar
                    open={toastOpen}
                    autoHideDuration={5000}
                    onClose={onToastClosed}
                  >
                    <Alert severity={toastType}>{toastText}</Alert>
                  </Snackbar>
                </Pages.PageBase>
              </Route>
              <Route exact path="/auth">
                <Pages.LoginPage />
              </Route>
              <Route>
                <Redirect to="/home" />
              </Route>
            </Switch>
          </Router>
        </GlobalContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;

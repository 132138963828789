import * as React from "react";
import { Device, UserData } from "src/common/types";
import { capitalize } from "lodash";
import { getDateString } from "src/common/utils";
import * as Icon from "@material-ui/icons";
import styles from "./DeviceMarker.module.scss";
import keyBy from "lodash/keyBy";
import { GlobalContext } from "src/App";
// import ImageView from "../../../components/ImageView";

interface DeviceMarkerProps {
  isOpen?: boolean;
  onOpen: () => void;
  onClose: () => void;
  device: Device;
  lat: number;
  lng: number;
  userData: UserData;
}

const DeviceMarker: React.FC<DeviceMarkerProps> = ({
  isOpen,
  onOpen,
  onClose,
  device,
  userData,
}) => {
  const { lastMeasurements } = React.useContext(GlobalContext);
  const latestMeasurement = React.useMemo(
    () =>
      isOpen
        ? lastMeasurements?.find(({ deviceID }) => deviceID === device.id)
        : undefined,
    [device.id, isOpen, lastMeasurements]
  );
  // const [imageViewTarget, setImageViewTarget] = React.useState<
  //   string | undefined
  // >(undefined);

  const crops = keyBy(userData.crops, "id");
  const pests = keyBy(userData.pests, "id");
  if (!isOpen) {
    return (
      <div className={styles.closedContainer} onClick={onOpen}>
        <Icon.SettingsRemoteOutlined />
        <h1>{device.uid}</h1>
        <div className={styles.markerPoint} />
      </div>
    );
  }

  return (
    <div className={styles.openContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.uidContainer}>
          <Icon.SettingsRemoteOutlined />
          <h1>{device.uid}</h1>
        </div>
        <Icon.CloseRounded className={styles.closeButton} onClick={onClose} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.infoDisplay}>
          <h2>Last active</h2>
          <h2 className={styles.data}>{getDateString(device.lastActivity)}</h2>
        </div>
        <div className={styles.infoDisplay}>
          <h2>Crop</h2>
          {device.cropID ? (
            <h2 className={styles.data}>
              {capitalize(crops[device.cropID].name)}
            </h2>
          ) : (
            <h2 className={styles.data}>&mdash;</h2>
          )}
        </div>
        <div className={styles.infoDisplay}>
          <h2>Pest</h2>
          {device.pestID ? (
            <h2 className={styles.data}>
              {capitalize(pests[device.pestID].name)}
            </h2>
          ) : (
            <h2 className={styles.data}>&mdash;</h2>
          )}
        </div>
        <div className={styles.measurementContainer}>
          <div className={styles.title}>
            <Icon.TimelineOutlined className={styles.icon} />
            <p>Latest measurements</p>
          </div>
          <div className={styles.list}>
            {latestMeasurement ? (
              <>
                {/* {latestMeasurement.imageId && (
                  <div
                    className={`${styles.row} ${styles.clickable}`}
                    onClick={() =>
                      setImageViewTarget(latestMeasurement.imageId)
                    }
                  >
                    <p>Trap image</p>
                    <Icon.OpenInNewOutlined className={styles.icon} />
                  </div>
                )} */}
                <div className={styles.row}>
                  <p>Detection count</p>
                  <p>{latestMeasurement.detectionCount}</p>
                </div>
                <div className={styles.row}>
                  <p>Temperature</p>
                  <p>{`${latestMeasurement.temperature} °C`}</p>
                </div>
                <div className={styles.row}>
                  <p>Humidity</p>
                  <p>{`${latestMeasurement.humidity} %`}</p>
                </div>
              </>
            ) : (
              <div className={styles.row}>
                <p
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Icon.NotInterestedOutlined className={styles.icon} />
                  No data available
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.markerPoint} />
      {/* {imageViewTarget && (
        <ImageView
          deviceImei={imageViewTarget}
          onClose={() => {
            setImageViewTarget(undefined);
          }}
        />
      )} */}
    </div>
  );
};

export default DeviceMarker;

import React, { useState, useEffect } from 'react'
import { Pagination } from 'src/components/Generic'
import * as Types from 'src/common/types'
import { DeviceDisplay } from './DeviceDisplay'
import styles from './DeviceList.module.scss'

interface Props {
  devices: Types.Device[]
  lastMeasurements: Types.Measurement[]
  userData: Types.UserData
  deviceFilter: Types.DeviceFilter
}

export const DeviceList: React.FC<Props> = ({ devices, lastMeasurements, userData, deviceFilter }) => {
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [filteredDevices, setFilteredDevices] = useState<Types.Device[]>([])
  const [filteredLastMeasurements, setFilteredLastMeasurements] = useState<Types.Measurement[]>([])
  const [displayedDevices, setDisplayedDevices] = useState<JSX.Element[]>([])
  const pageSize = 20

  useEffect(() => {
    setFilteredDevices(
      devices.filter(
        (d) =>
          // UID
          (deviceFilter.uids.length > 0 ? deviceFilter.uids.includes(d.uid) : true) &&
          // IMEI
          (deviceFilter.imeis.length > 0 ? deviceFilter.imeis.includes(d.imei) : true) &&
          // Location
          (deviceFilter.locations.length > 0
            ? deviceFilter.locations.includes(d.locationConfigID)
            : true) &&
          // Crop
          (deviceFilter.crops.length > 0 ? deviceFilter.crops.includes(d.cropID) : true) &&
          // Pest
          (deviceFilter.pests.length > 0 ? deviceFilter.pests.includes(d.pestID) : true) &&
          // Commission status
          (deviceFilter.commissionStatuses.length > 0
            ? deviceFilter.commissionStatuses.includes(d.commissionStatusID)
            : true) &&
          // Operation config
          (deviceFilter.configs.length > 0
            ? deviceFilter.configs.includes(d.operationConfigID)
            : true)
      )
    )
  }, [devices, deviceFilter])

  useEffect(() => {
    const newPageCount = Math.ceil(filteredDevices.length / pageSize)
    setPageCount(newPageCount)
    if (page > newPageCount) {
      setPage(newPageCount)
    }
    setFilteredLastMeasurements(
      lastMeasurements.filter((m) => filteredDevices.find(d => d.id === m.deviceID) !== undefined)
    )
  }, [filteredDevices])

  useEffect(() => {
    if (page < pageCount) {
      setPage(1)
    }
    if (page > pageCount) {
      setPage(pageCount)
    }
  }, [pageCount])

  useEffect(() => {
    const start = (page - 1) * pageSize
    const end = start + pageSize
    setDisplayedDevices(
      filteredDevices
        .slice(start, end)
        .map((d, idx) => <DeviceDisplay key={idx} device={d} lastMeasurement={filteredLastMeasurements.find(m => m.deviceID === d.id) ?? null} userData={userData} />)
    )
  }, [page, filteredDevices])

  return (
    <div className={styles.container}>
      <div className={styles.paginationContainer}>
        <span>
          <h1>Devices</h1>
          <h3>
            Displaying filter results (
            {`${Math.max(0, (page - 1) * pageSize + 1)}-${Math.min(
              displayedDevices.length,
              page * pageSize
            )}`}{' '}
            / {displayedDevices.length})
          </h3>
        </span>
        <Pagination index={page} count={pageCount} onChange={(x) => setPage(x)} />
      </div>
      <div className={styles.deviceList}>
        {displayedDevices.length === 0 ? (
          <div className={styles.placeholder}>No devices to show</div>
        ) : (
          displayedDevices
        )}
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { UserData } from 'src/common/types'
import { Member } from './Member'
import { UserHistoryDisplay } from './UserHistoryDisplay'
import { Pagination, CollapsibleCard } from 'src/components/Generic'

import styles from './TeamHistory.module.scss'

export const TeamHistory: React.FC<{ userData: UserData }> = ({ userData }) => {
  const [enabledMembers, setEnabledMembers] = useState(
    [userData.user.id].concat(userData.team.map((u) => u.id))
  )
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [filteredHistory, setFilteredHistory] = useState(userData.history)
  const [displayedHistory, setDisplayedHistory] = useState<JSX.Element[]>([])

  const pageSize = 20

  useEffect(() => {
    setFilteredHistory(userData.history.filter((e) => enabledMembers.includes(e.userID)))
  }, [enabledMembers, userData.history])

  useEffect(() => {
    const newPageCount = Math.ceil(filteredHistory.length / pageSize)
    setPageCount(newPageCount)
    if (page > newPageCount) {
      setPage(newPageCount)
    }
  }, [filteredHistory])

  useEffect(() => {
    if (page < pageCount) {
      setPage(1)
    }
    if (page > pageCount) {
      setPage(pageCount)
    }
  }, [pageCount])

  useEffect(() => {
    const start = (page - 1) * pageSize
    const end = start + pageSize
    setDisplayedHistory(
      filteredHistory
        .slice(start, end)
        .map((e, idx) => <UserHistoryDisplay key={idx} user={findUserByID(e.userID)} event={e} />)
    )
  }, [page, filteredHistory])

  function toggleMember(id: number) {
    if (enabledMembers.includes(id)) {
      if (enabledMembers.length === 1) return
      setEnabledMembers(enabledMembers.filter((x) => x !== id))
    } else {
      setEnabledMembers([...enabledMembers, id])
    }
  }

  function findUserByID(id: number) {
    const user = [userData.user].concat(userData.team).find((u) => u.id === id)
    if (!user) {
      return userData.user
    }
    return user
  }

  return (
    <CollapsibleCard
      title='Team history'
      subtitle='Overview team activity history'
      actionSet={<Pagination index={page} count={pageCount} onChange={(x) => setPage(x)} />}
      openByDefault
    >
      <>
        <div className={styles.memberList}>
          <Member
            user={userData.user}
            onClick={() => toggleMember(userData.user.id)}
            enabled={enabledMembers.includes(userData.user.id)}
          />
          {userData.team.map((u, idx) => (
            <Member
              key={idx}
              user={u}
              onClick={() => toggleMember(u.id)}
              enabled={enabledMembers.includes(u.id)}
            />
          ))}
        </div>
        <div className={styles.userHistoryList}>
          {displayedHistory.length > 0 ? (
            displayedHistory
          ) : (
            <div className={styles.placeholder}>No entries to show</div>
          )}
        </div>
      </>
    </CollapsibleCard>
  )
}

import React from 'react'
import { Button, Select, MenuItem } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import styles from './Pagination.module.scss'

interface PaginationProps {
  index: number
  count: number
  onChange: (index: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({ index, count, onChange }) => {
  function onPrevious() {
    if (index > 1) {
      onChange(index - 1)
    }
  }

  function onNext() {
    if (index < count) {
      onChange(index + 1)
    }
  }

  function menuItems(c: number) {
    if (count === 0) {
      return <MenuItem value={0}>...</MenuItem>
    }
    const items = []
    for (let i = 0; i < c; i++) {
      items.push(
        <MenuItem key={i} value={i + 1}>
          {i + 1}
        </MenuItem>
      )
    }
    return items
  }

  return (
    <div className={styles.pagination}>
      <Button className={styles.button} onClick={onPrevious}>
        <NavigateBefore />
      </Button>
      <Select
        className={styles.select}
        variant='outlined'
        value={index}
        onChange={(e) => onChange(e.target.value as number)}
      >
        {menuItems(count)}
      </Select>
      <Button className={styles.button} onClick={onNext}>
        <NavigateNext />
      </Button>
    </div>
  )
}

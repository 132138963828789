import React, { useState } from 'react'
import {
  CategoryOutlined as TargetIcon,
  Schedule as TimeIcon,
  PersonOutline as UserIcon,
  NavigateNext as DetailsIcon,
} from '@material-ui/icons'
import { User, UserHistory } from 'src/common/types'
import * as Utils from 'src/common/utils'

import styles from './UserHistoryDisplay.module.scss'
import { Collapse } from '@material-ui/core'

export const UserHistoryDisplay: React.FC<{ user: User; event: UserHistory }> = ({
  user,
  event,
}) => {
  const [detailsVisible, setDetailsVisible] = useState(false)

  return (
    <div className={styles.userHistoryDisplay} onClick={() => setDetailsVisible(!detailsVisible)}>
      <div className={styles.summary}>
        <div className={styles.block}>
          <UserIcon className={styles.icon} />
          {user.name}
        </div>
        <div className={styles.event}>{event.event}</div>
        {event.target ? (
          <div className={styles.block}>
            <TargetIcon className={styles.icon} />
            {event.target}
          </div>
        ) : null}

        <div className={styles.time}>
          <TimeIcon className={styles.icon} />
          {Utils.getDateTimeString(event.timestamp)}
        </div>
      </div>
      <Collapse className={styles.details} in={detailsVisible}>
        <div className={styles.content}>
          <DetailsIcon className={styles.icon} />
          {event.details ? event.details : 'no additional details available'}
        </div>
      </Collapse>
    </div>
  )
}

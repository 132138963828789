import urls from "./urls";
import * as Types from "src/common/types";
import * as ApiConfig from "./config";

export async function login(
  credentials: Types.Crendentials
): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.login, {
    method: "POST",
    headers: ApiConfig.baseHeaders,
    body: JSON.stringify(credentials),
    credentials: "include",
  });

  return (await r.json()) as Types.GenericResponse<string>;
}

export async function logout(): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.logout, {
    method: "POST",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
  });

  return (await r.json()) as Types.GenericResponse<string>;
}

export async function createUser(data: {
  email: string;
  name: string;
}): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.user, {
    method: "POST",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<string>;
}

export async function updateUser(data: {
  userID: number;
  hasAdminAccess: boolean;
  hasWriteAccess: boolean;
}): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.user, {
    method: "PATCH",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<string>;
}

export async function deleteUser(data: {
  userID: number;
}): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.user, {
    method: "DELETE",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<string>;
}

export async function getUserData(): Promise<
  Types.GenericResponse<Types.UserData>
> {
  const r = await fetch(urls.userData, {
    method: "GET",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
  });

  return (await r.json()) as Types.GenericResponse<Types.UserData>;
}

export async function createSavedFilter(data: {
  name: string;
  filterParams: Types.DeviceFilter;
}): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.savedFilter, {
    method: "POST",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<string>;
}

export async function deleteSavedFilter(data: {
  filterID: number;
}): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.savedFilter, {
    method: "DELETE",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<string>;
}
export async function getMapsApiKey(): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.mapsApiKey, {
    method: "GET",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
  });
  return (await r.json()) as Types.GenericResponse<string>;
}

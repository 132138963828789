//APP STATE -> PRODUCTION OR DEVELOPMENT
const built = process.env.NODE_ENV === "production";
const production = true;
const useLocalAPI = true;
const appName = "Admin dashboard";
const appVersion = "v0.1";

export const config = {
  built,
  production,
  useLocalAPI,
  appName,
  appVersion,
};

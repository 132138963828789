import React from 'react'
import { useHistory } from 'react-router'
import { Button, Tooltip } from '@material-ui/core'
import {
  BatteryFullRounded as BatteryIcon,
  OfflineBoltRounded as SolarIcon,
  Schedule as ActivityIcon,
  Public as LocationIcon,
  BugReportOutlined as PestIcon,
  SpaOutlined as CropIcon,
  Brightness7Rounded as TemperatureIcon,
  OpacityRounded as HumidityIcon,
  EmojiNatureRounded as DetectionCountDeltaIcon,
  Code as FirmwareIcon,
  SyncAlt as FirmwareUpdateIcon,
  SettingsOutlined as ConfigIcon,
  LocalShippingOutlined as CommissionIcon,
  SignalCellularAltRounded as RSSIIcon,
} from '@material-ui/icons'
import { Device, Measurement, UserData } from 'src/common/types'
import * as Utils from 'src/common/utils'

import styles from './DeviceDisplay.module.scss'

interface DeviceDetailProps {
  tooltip: string
  text: number | string | null
  Icon: any
  hideEmpty?: boolean
  onClick?: () => void
}

const DeviceDetail: React.FC<DeviceDetailProps> = ({ tooltip, text, Icon, hideEmpty, onClick }) => {
  if (hideEmpty && text === null) {
    return null
  }

  return (
    <Tooltip title={<span className={styles.tooltip}>{tooltip}</span>} arrow placement='top'>
      <div className={`${styles.block} ${onClick ? styles.clickable : null}`}>
        <Icon className={styles.icon} />
        {text}
      </div>
    </Tooltip>
  )
}

interface Props {
  device: Device
  lastMeasurement: Measurement | null
  userData: UserData
}

export const DeviceDisplay: React.FC<Props> = ({ device, lastMeasurement, userData }) => {
  const history = useHistory()

  const batteryStatusStyle = [
    styles.block,
    device.batteryVoltage === null || device.batteryVoltage < 3.9 ? styles.warning : '',
    device.batteryVoltage && device.batteryVoltage < 3.6 ? styles.critical : '',
  ].filter((x) => x !== '')

  const solarStatusStyle = [
    styles.block,
    device.solarCellVoltage === null || device.solarCellVoltage < 10 ? styles.warning : '',
    device.solarCellVoltage && device.solarCellVoltage < 5 ? styles.critical : '',
  ].filter((x) => x !== '')

  const statusIndicatorStyle = [
    styles.statusIndicator,
    batteryStatusStyle.length === 2 || solarStatusStyle.length === 2 ? styles.warning : '',
    batteryStatusStyle.length === 3 || solarStatusStyle.length === 3 ? styles.critical : '',
  ]

  return (
    <div className={styles.deviceDisplay}>
      <div className={styles.summary}>
        <div className={styles.ids}>
          <div className={statusIndicatorStyle.join(' ')} />
          <div className={styles.texts}>
            <h1>{device.uid}</h1>
            <h2>{device.imei}</h2>
          </div>
        </div>

        <div className={batteryStatusStyle.join(' ')}>
          <BatteryIcon className={styles.icon} />
          {device.batteryVoltage === null ? '...' : device.batteryVoltage.toFixed(2) + ' V'}
        </div>

        <div className={solarStatusStyle.join(' ')}>
          <SolarIcon className={styles.icon} />
          {device.solarCellVoltage === null ? '...' : device.solarCellVoltage.toFixed(2) + ' V'}
        </div>

        {userData.user.hasAdminAccess && (
          <div className={styles.block}>
            <RSSIIcon className={styles.icon} />
            {device.networkRSSI || '...'}
          </div>
        )}
        
        <div className={styles.block}>
          <ActivityIcon className={styles.icon} />
          {Utils.getDateTimeString(device.lastActivity)}
        </div>
        <Button
          onClick={() => history.push('/home/device/#' + device.imei)}
          className={styles.button}
          variant='outlined'
          color='secondary'
        >
          Open
        </Button>
      </div>
      <div className={styles.details}>
        <DeviceDetail
          tooltip='Location'
          text={
            Utils.getByID(
              device.locationConfigID,
              userData.locationConfigs
            )?.countryCode.toUpperCase() ?? null
          }
          Icon={LocationIcon}
        />
        <DeviceDetail
          tooltip='Crop'
          text={Utils.getByID(device.cropID, userData.crops)?.name ?? null}
          Icon={CropIcon}
        />
        <DeviceDetail
          tooltip='Target pest'
          text={Utils.getByID(device.pestID, userData.pests)?.name ?? null}
          Icon={PestIcon}
        />
        {userData.user.hasAdminAccess && (
        <>
          <DeviceDetail
            tooltip='Commission status'
            text={Utils.getByID(device.commissionStatusID, userData.commissionStatuses)?.name ?? null}
            Icon={CommissionIcon}
          />
          <DeviceDetail
            tooltip='Operation configuration'
            text={Utils.getByID(device.operationConfigID, userData.operationConfigs)?.name ?? null}
            Icon={ConfigIcon}
          />
          <DeviceDetail
            tooltip='Firmware version'
            text={Utils.getByID(device.firmwareID, userData.firmwares)?.version ?? null}
            Icon={FirmwareIcon}
          />
          <DeviceDetail
            tooltip='Target firmware version'
            text={Utils.getByID(device.targetFirmwareID, userData.firmwares)?.version ?? null}
            Icon={FirmwareUpdateIcon}
            hideEmpty
          />
        </>
        )}
        <DeviceDetail
          tooltip='Latest temperature'
          text={(lastMeasurement !== null && lastMeasurement.temperature !== null) ? lastMeasurement.temperature + ' C' : 'Unknown'}
          Icon={TemperatureIcon}
        />
        <DeviceDetail
          tooltip='Latest humidity'
          text={(lastMeasurement !== null && lastMeasurement.humidity !== null) ? lastMeasurement.humidity + ' %' : 'Unknown'}
          Icon={HumidityIcon}
        />
        <DeviceDetail
          tooltip='Daily catch'
          text={(lastMeasurement !== null && lastMeasurement.detectionCountDelta !== null) ? lastMeasurement.detectionCountDelta : 'Unknown'}
          Icon={DetectionCountDeltaIcon}
        />
      </div>
    </div>
  )
}

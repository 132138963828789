import React, { useState, useEffect } from 'react'
import { Pagination, CollapsibleCard } from 'src/components/Generic'
import { CircularProgress } from '@material-ui/core'
import * as Types from 'src/common/types'
import { DeviceHistoryDisplay } from './DeviceHistoryDisplay'
import { API } from 'src/api'
import styles from './DeviceHistory.module.scss'

interface Props {
  userData: Types.UserData
  device: Types.Device
}

export const DeviceHistory: React.FC<Props> = ({ userData, device }) => {
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [events, setEvents] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [displayedEvents, setDisplayedEvents] = useState<JSX.Element[]>([])
  const pageSize = 10

  useEffect(() => {
    updateEventList()
  }, [page])

  useEffect(() => {
    if (page < pageCount) {
      setPage(1)
    }
    if (page > pageCount) {
      setPage(pageCount)
    }
  }, [pageCount])

  useEffect(() => {
    setDisplayedEvents(
      events.map((e, idx) => <DeviceHistoryDisplay key={idx} userData={userData} event={e} />)
    )
  }, [events])

  async function updateEventList() {
    setLoading(true)
    const r = await API.Device.deviceEvents(device.imei, pageSize, page)
    if (r.result === 'ok') {
      setTotalCount(r.message.totalCount)
      setPageCount(r.message.pageCount)
      setEvents(r.message.events)
    }
    setLoading(false)
  }

  return (
    <CollapsibleCard
      title='History'
      subtitle={`Displaying device events (${Math.max(0, (page - 1) * pageSize + 1)}-${Math.min(
        totalCount,
        page * pageSize
      )} / ${totalCount})`}
      actionSet={<Pagination index={page} count={pageCount} onChange={(x) => setPage(x)} />}
    >
      <div className={styles.eventList}>
        {loading ? (
          <div className={styles.loadingIndicator}>
            <CircularProgress />
          </div>
        ) : displayedEvents.length === 0 ? (
          <div className={styles.placeholder}>No events to show</div>
        ) : (
          displayedEvents
        )}
      </div>
    </CollapsibleCard>
  )
}

import * as React from "react";
import styles from "./index.module.scss";
import * as Types from "src/common/types";
import { CollapsibleCard } from "src/components";
import { CircularProgress } from "@material-ui/core";
import MeasurementChart from "src/components/MeasurementChart";

import { API } from "src/api";
type DeviceMeasurementsByIdProps = {
  id: number;
};
const DeviceMeasurementsById: React.VFC<DeviceMeasurementsByIdProps> = ({
  id,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<Types.MeasurementSeries>([]);
  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const fetch = async () => {
      const res = await API.Device.getDeviceMeasurementsById({ id });

      setIsLoading(false);

      if (res.result === "ok") setData(res.message);
      else setError("An error occured during data fetching");
    };

    fetch();
  }, []);

  let content = null;
  if (isLoading)
    content = (
      <div className={styles.loaderContainer}>
        <CircularProgress />
      </div>
    );
  else if (error) content = error;
  else {
    content = <MeasurementChart data={data} />;
  }

  return (
    <CollapsibleCard title="Device Measurements" subtitle="" openByDefault>
      <div className={styles.container}>{content}</div>
    </CollapsibleCard>
  );
};
export default DeviceMeasurementsById;

import * as React from "react";
import * as Types from "src/common/types";
import { GlobalContext } from "src/App";
import capitalize from "lodash/capitalize";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  LineElement,
  LineController,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import isNil from "lodash/isNil";
import format from "date-fns/format";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Legend,
  Tooltip
);

const colors: Record<string, string> = {
  humidity: "rgba(20, 135, 245, 0.4)",
  temperature: "rgba(250, 94, 52, 0.7)",
  2: "rgb(250, 194, 52)",
  3: "rgb(0, 200, 10)",
};

type MeasurementChartProps = {
  data: Types.MeasurementSeries;
};
const MeasurementChart: React.VFC<MeasurementChartProps> = (props) => {
  const { userData } = React.useContext(GlobalContext);

  if (props.data.length === 0) return <>No data</>;

  const options = {
    responsive: true,
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        boxWidth: 10,
        fontSize: 13,
      },
    },
    plugins: {
      tooltip: {
        mode: "index" as const,
        position: "average" as const,
        bodyFontSize: 13,
        callbacks: {
          label: function (context: any) {
            const label = context?.dataset?.label;
            const y = context?.dataset?.data?.[context?.dataIndex];
            console.log(label, context);
            if (isNil(label) || isNil(y)) return "";

            const value = y.toFixed(1);
            switch (label) {
              case "Temperature":
                return `${label}: ${value} °C`;
              case "Humidity":
                return `${label}: ${value} %`;
              default:
                return `${label}: ${value}`;
            }
          },
        },
      },
    },
    scales: {
      count: {
        type: "linear" as const,
        position: "right" as const,
        min: 0,
        // max: 100,
        title: {
          display: true,
          text: "Daily catch",
        },
      },
      "temp/hum": {
        type: "linear" as const,
        position: "left" as const,
        // min: -100,
        // max: 100,
        title: {
          display: true,
          text: "Temperature / Humidity",
        },
      },
    },
  };

  const labels = props.data.map(({ timestamp }) =>
    format(new Date(timestamp * 1000), "MM/dd/yyyy")
  );
  const datasets = [
    ...(userData?.pests
      .filter(({ id }) => id !== 1)
      .map(({ id, name }) => ({
        label: capitalize(name),
        data: props.data.map(({ detectionCountDelta }) =>
          isNil(detectionCountDelta?.[id])
            ? null
            : detectionCountDelta[id] < 0
            ? null
            : detectionCountDelta[id]
        ),
        type: "line" as const,
        pointRadius: 4,
        pointHoverRadius: 5,
        pointBorderWidth: 0,
        lineTension: 0,
        yAxisID: "count",
        spanGaps: true,
        borderColor: colors[id],
        backgroundColor: colors[id],
      })) ?? []),
    {
      label: "Humidity",
      yAxisID: "temp/hum",
      borderColor: colors["humidity"],
      backgroundColor: colors["humidity"],
      data: props.data.map(({ humidity }) => humidity ?? null),
    },
    {
      label: "Temperature",
      yAxisID: "temp/hum",
      borderColor: colors["temperature"],
      backgroundColor: colors["temperature"],
      data: props.data.map(({ temperature }) => temperature ?? null),
    },
  ];

  const data = { labels, datasets };
  return <Chart type="bar" data={data} options={options} />;
};
export default MeasurementChart;

import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, Button } from '@material-ui/core'
import { Schedule as TimeIcon } from '@material-ui/icons'
import { User } from 'src/common/types'
import * as Utils from 'src/common/utils'

import styles from './MemberDisplay.module.scss'

interface Props {
  user: User
  current: User
  onRoleChange: (user: User, role: { hasAdminAccess: boolean; hasWriteAccess: boolean }) => void
  onDelete: (user: User) => void
}

export const MemberDisplay: React.FC<Props> = ({ user, current, onRoleChange, onDelete }) => {
  const [hasWriteAccess, setHasWriteAccess] = useState(user.hasWriteAccess)
  const [hasAdminAccess, setHasAdminAccess] = useState(user.hasAdminAccess)

  function onAdminAccessChange(value: boolean) {
    setHasAdminAccess(value)
  }

  function onWriteAccessChange(value: boolean) {
    setHasWriteAccess(value)
  }

  useEffect(() => {
    if (hasWriteAccess !== user.hasWriteAccess || hasAdminAccess !== user.hasAdminAccess) {
      onRoleChange(user, { hasAdminAccess, hasWriteAccess })
    }
  }, [hasAdminAccess, hasWriteAccess])

  return (
    <div className={styles.member}>
      <div className={styles.infoContainer}>
        <div className={styles.nameContainer}>
          <p className={styles.name}>
            {user.name + (current.email === user.email ? ' (you)' : '')}
          </p>
          <p className={styles.email}>{user.email}</p>
        </div>
        <p className={styles.lastActivity}>
          <TimeIcon className={styles.icon} />
          <span>{Utils.getDateString(user.lastActivity)}</span>
        </p>
      </div>
      {user.email === current.email || user.id === 1 ? null : (
        <div className={styles.actionContainer}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => onAdminAccessChange(e.target.checked)}
                color='primary'
                checked={hasAdminAccess}
                disabled={!current.hasAdminAccess}
              />
            }
            label='Administrator'
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => onWriteAccessChange(e.target.checked)}
                color='primary'
                checked={hasWriteAccess || hasAdminAccess}
                disabled={!current.hasAdminAccess || user.hasAdminAccess}
              />
            }
            label='Write access'
          />
          <Button
            color='secondary'
            variant='outlined'
            disabled={!current.hasAdminAccess}
            onClick={() => onDelete(user)}
            className={styles.button}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  )
}

import urls from "./urls";
import * as Types from "src/common/types";
import * as ApiConfig from "./config";

export async function deviceList(): Promise<
  Types.GenericResponse<Types.Device[]>
> {
  const r = await fetch(urls.devices, {
    method: "GET",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
  });

  return (await r.json()) as Types.GenericResponse<Types.Device[]>;
}

export async function deviceDetails(data: {
  imeis: string[];
}): Promise<Types.GenericResponse<Types.Device[]>> {
  const r = await fetch(urls.deviceDetails, {
    method: "POST",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<Types.Device[]>;
}

export async function updateDevices(data: {
  [imei: string]: Types.DeviceUpdateData;
}): Promise<Types.GenericResponse<Types.Device[]>> {
  const r = await fetch(urls.deviceDetails, {
    method: "PATCH",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<Types.Device[]>;
}

export async function deviceLastMeasurements(data: {
  deviceIDs: number[];
}): Promise<Types.GenericResponse<Types.Measurement[]>> {
  const r = await fetch(urls.deviceLastMeasurements, {
    method: "POST",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<Types.Measurement[]>;
}

export async function getDeviceMeasurements(): Promise<
  Types.GenericResponse<Types.MeasurementSeries>
> {
  const to = Math.floor(Date.now() / 1000);
  const from = to - 60 * 24 * 60 * 60;
  const r = await fetch(`${urls.getDeviceMeasurements}?from=${from}&to=${to}`, {
    method: "GET",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    // body: JSON.
  });
  return (await r.json()) as Types.GenericResponse<Types.MeasurementSeries>;
}

export async function getDeviceMeasurementsById(data: {
  id: number;
}): Promise<Types.GenericResponse<Types.MeasurementSeries>> {
  const to = Math.floor(Date.now() / 1000);
  const from = to - 60 * 24 * 60 * 60;
  const r = await fetch(
    `${urls.getDeviceMeasurementsById(data.id)}?from=${from}&to=${to}`,
    {
      method: "GET",
      headers: ApiConfig.baseHeaders,
      credentials: "include",
      // body: JSON.
    }
  );
  return (await r.json()) as Types.GenericResponse<Types.MeasurementSeries>;
}

export async function overrideDetectionCount(
  data: Types.DetectionCountOverrideData
): Promise<Types.OverrideDetectionCountResponse> {
  const r = await fetch(urls.overrideDetectionCountUrl, {
    method: "PATCH",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.OverrideDetectionCountResponse;
}

export async function clearDetectionCount(
  data: Types.DetectionCountClearData
): Promise<Types.GenericResponse<string>> {
  const r = await fetch(urls.clearDetectionCountUrl, {
    method: "PATCH",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
    body: JSON.stringify(data),
  });

  return (await r.json()) as Types.GenericResponse<string>;
}

export async function deviceEvents(
  imei: string,
  pageSize: number,
  page: number
): Promise<Types.DeviceEventListResponse> {
  const r = await fetch(urls.deviceEvents + `/${imei}/${pageSize}/${page}`, {
    method: "GET",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
  });

  return (await r.json()) as Types.DeviceEventListResponse;
}

export async function deviceActivityGraph(
  imei: string,
  days: number
): Promise<Types.GenericResponse<Types.DeviceActivityGraphData>> {
  const r = await fetch(urls.deviceActivity + `/${imei}/${days}`, {
    method: "GET",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
  });

  return (await r.json()) as Types.GenericResponse<Types.DeviceActivityGraphData>;
}

export async function getImageUrl(
  imei: string,
  index: number
): Promise<Types.ImageURLResponse> {
  const r = await fetch(urls.imageUrl + `/${imei}/${index}`, {
    method: "GET",
    headers: ApiConfig.baseHeaders,
    credentials: "include",
  });

  return (await r.json()) as Types.ImageURLResponse;
}

export async function deviceDiagnosticWindow(
  imei: string,
  windowSize: number,
  windowIndex: number
): Promise<Types.GenericResponse<Types.DeviceDiagnostic[]>> {
  const r = await fetch(
    urls.deviceDiagnosticWindow + `/${imei}/${windowSize}/${windowIndex}`,
    {
      method: "GET",
      headers: ApiConfig.baseHeaders,
      credentials: "include",
    }
  );

  return (await r.json()) as Types.GenericResponse<Types.DeviceDiagnostic[]>;
}

import * as Types from './types'

export function getUserByID(userID: number, users: Types.User[]) {
  return users.find((u) => u.id === userID)
}

export function getInitials(user: Types.User) {
  const names = user.name.split(' ')
  if (names.length > 1) {
    return names[0][0] + names[1][0]
  } else {
    return user.name[0]
  }
}

export function getDateString(utcEpoch: number) {
  const date = new Date(utcEpoch * 1000)
  const today = new Date()
  const year = today.getFullYear() > date.getFullYear() ? date.getFullYear() + ' ' : ''
  return `${year}${(date.getMonth() + 1).toString().padStart(2, '0')}. ${date
    .getDate()
    .toString()
    .padStart(2, '0')}.`
}

export function getDateTimeString(utcEpoch: number) {
  const date = new Date(utcEpoch * 1000)
  const today = new Date()
  const year = today.getFullYear() > date.getFullYear() ? date.getFullYear() + ' ' : ''
  let day = ''
  if (
    today.getFullYear() !== date.getFullYear() ||
    today.getMonth() !== date.getMonth() ||
    today.getDate() !== date.getDate()
  ) {
    day = `${(date.getMonth() + 1).toString().padStart(2, '0')}. ${date
      .getDate()
      .toString()
      .padStart(2, '0')}. `
  }
  return `${year}${day}${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
}

export function matchList(list: string[], test: RegExp): boolean {
  if (list.length === 0) return true
  return list.every((x) => test.test(x))
}

export function splitList(value: string): string[] {
  return value
    .split(',')
    .map((x) => x.trimStart().trimEnd())
    .filter((x) => x !== '')
}

export function locationConfigDisplayName(c: Types.LocationConfig) {
  let name = `${c.continent} / ${c.countryCode.toUpperCase()} - ${c.country}`
  if (c.region) name += ` / ${c.region}`
  if (c.comment) name += ` [${c.comment}]`
  return name
}

export function operationConfigDisplayName(c: Types.OperationConfig) {
  let name = `${c.name}`
  if (c.comment) name += ` [${c.comment}]`
  return name
}

export function firmwareDisplayName(f: Types.Firmware) {
  let name = f.version
  if (f.comment !== '') name += ` [${f.comment}]`
  return name
}

export function getByID<T extends { id: number }>(id: number | null, list: T[]) {
  if (id === null) return undefined
  return list.find((i) => i.id === id)
}

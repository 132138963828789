import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { CircularProgress, Tooltip } from "@material-ui/core";
import {
  DashboardOutlined as HomeIcon,
  ExitToAppOutlined as LogoutIcon,
  PeopleOutlined as TeamIcon,
} from "@material-ui/icons";
import { GlobalContext } from "src/App";
import * as Types from "src/common/types";
import styles from "./Menu.module.scss";
import userDisplayStyles from "./UserDisplay.module.scss";
import { API } from "src/api";
import { config } from "src/config";

const UserDisplay: React.FC<{ user?: Types.User }> = ({ user }) => {
  if (!user) {
    return (
      <div className={userDisplayStyles.loadingIndicator}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={userDisplayStyles.container}>
      <div className={userDisplayStyles.info}>
        <h1>{user.name}</h1>
        <h2>{user.email}</h2>
      </div>
    </div>
  );
};

interface MenuButtonProps {
  label: string;
  onClick: () => void;
  Icon?: any;
  selected?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  label,
  onClick,
  Icon,
  selected,
}) => {
  return (
    <div
      className={`${styles.menuButton} ${selected ? styles.selected : null}`}
      onClick={onClick}
    >
      <p>{label}</p>
      {Icon ? <Icon className={styles.icon} /> : null}
    </div>
  );
};

export const Menu: React.FC = () => {
  const { userData, updateUserData } = useContext(GlobalContext);
  const history = useHistory();
  const path = useLocation();

  function navigate(location: string) {
    history.push(location);
  }

  async function logout() {
    await API.Account.logout();
    updateUserData();
  }

  function onLogoClick() {
    window.location.reload();
  }

  return (
    <div className={styles.container}>
      <Tooltip
        arrow
        placement="bottom"
        title={`${config.appName} | ${config.appVersion} | ${
          config.production ? "live" : "preview"
        }`}
      >
        <img
          onClick={onLogoClick}
          className={styles.logo}
          src={`${process.env.PUBLIC_URL}/svg/logo-h-black.svg`}
        />
      </Tooltip>
      <div className={styles.buttons}>
        <MenuButton
          label="Devices"
          onClick={() => navigate("/home")}
          selected={path.pathname === "/home"}
          Icon={HomeIcon}
        />
        {userData?.user.hasAdminAccess && (
          <MenuButton
            label="Team"
            onClick={() => navigate("/home/team")}
            selected={path.pathname === "/home/team"}
            Icon={TeamIcon}
          />
        )}
      </div>
      <UserDisplay user={userData?.user} />
      <MenuButton label="Log out" onClick={logout} Icon={LogoutIcon} />
    </div>
  );
};
